<template>
    <div>
        <a v-if="!hideLink" v-b-modal.forgotPassword href="#">
            <slot>Forgot Password?</slot>    
        </a>
        <b-modal lazy id="forgotPassword" title="Recover password" size="lg" hide-footer>
            <iframe :src="forgotPasswordUrl" height="315" width="100%" class="border-0"></iframe>
        </b-modal>
    </div>
</template>

<script setup lang="ts">
import { stringify } from 'query-string'


const props = withDefaults(defineProps<{
    instCodes?: string[] | string,
    servicesPath?: string,
    email?: string,
    username?: string,
    hideLink?: boolean
}>(), {
    instCodes: () => ['ESTIALABS', 'ESTIA'],
    servicesPath: '/services'
})

const instance = getCurrentInstance().proxy

const forgotPwMessage = (evt: MessageEvent) => {
    if (evt.data === 'close') {
        return instance.$bvModal.hide('forgotPassword')
    }
}

onMounted(() => {
    window.addEventListener('message', forgotPwMessage)
})

onUnmounted(() => {
    window.removeEventListener('message', forgotPwMessage)
})

const forgotPasswordUrl = computed(() => `${props.servicesPath}/admin/ForgotCredentials.jsp?${stringify({
        i: props.instCodes,
        emailadd: props.email || undefined,
        userName: props.username || undefined
    })}`
    )

const show = () => {
    instance.$bvModal.show('forgotPassword')
}

defineExpose({
    show
})

</script>